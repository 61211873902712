import * as React from "react"
import Layout from "../components/layout"
import "../main.scss"


export default function ThankYouPage() {

  return (
    <Layout>
      <header id="contact">Thank You</header>
      <div id="form-container">
      <div id="formWrapper">
      <div className="thank-you-wrapper">
        <h3>Thank you for your submission</h3>
        <br></br>
        <p>We will get back to you shortly.</p>
      </div>
    </div>
    </div>
    </Layout>
  )
}
